.date-range {
  text-align: right;
}

.date-range__calendars {
  min-width: 120px;
}

.date-range__calendars-component-wrap {
  width: 600px;
}

.date-range__date-time-format-error {
  margin: 1rem;
}

.date-range__custom-period-inputs {
  padding-top: 1rem;
}