.checkbox-toggle {
    max-height: 0;
    max-width: 0;
    opacity: 0;
}

.checkbox-toggle + label {
    position: relative;
    box-shadow: inset 0 0 0 1px #d5d5d5;
    text-indent: -5000px;
    height: 20px;
    width: 35px;
    border-radius: 15px;
    cursor: pointer;
    margin: 0;
    vertical-align: middle;
}

.checkbox-toggle + label:before {
    content: "";
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    top: 0;
    left: 0;
    border-radius: 15px;
    background: rgba(19, 191, 17, 0);
    transition: .25s ease-in-out;
}

.checkbox-toggle + label:after {
    content: "";
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    top: 0;
    left: 0;
    border-radius: 15px;
    background: white;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .2), 0 2px 4px rgba(0, 0, 0, .1);
    transition: .25s ease-in-out;
}

.checkbox-toggle:checked + label:before {
    width: 35px;
    background: #00bff0;
}

.checkbox-toggle:checked + label:after {
    left: 15px;
    box-shadow: inset 0 0 0 1px #00bff0, 0 2px 4px rgba(0, 0, 0, .1);
}