@import "text";

.widget__container {
    border: 1px solid #e6e6e6;
    border-radius: 3px;
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.widget__title--hover {
    cursor: pointer;
}

.widget__title {
    border-bottom: 1px solid #e6e6e6;
    border-radius: 3px 3px 0 0;
    font-weight: 500;
    font-size: 1.1em;
    padding: 10px 5px;
    color: #354052;
}

.widget__container--hover:hover {
    border: 1px solid #00bff0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

.widget__container--hover:hover .widget__title {
    background: #e6faff;
}

.widget__content {
    padding: 15px;
}

.widget__footer {
    color: #8a95a5;
    padding: 10px;
    background: #f5f8fc;
    border-top: 1px solid #e6e6e6;
    border-radius: 0 0 3px 3px;
    font-size: 13px;
}

.widget__title-column {
    display: inline-block;
}

.widget__title-column {
    vertical-align: middle;
}

.widget__title-column--icons {
    float: right;
}

.widget__title-column--license- {
    &warning,
    &reminder-message {
        @extend .text-semibold;
    }

    &reminder-message {
        font-size: 1.25rem;
        height: 43px;
        line-height: 43px
    }

    &warning {
        font-size: 1.25rem;
    }
}

.widget__title-column--icon {
    margin: 0 5px;
}

.widget__title-column--license-reminder-icon {
    margin: 0 5px;
}

.widget__content--license-activation-nodes-status {
    font-weight: normal;
}

.widget__content--license-activation-nodes .noderole {
    color: #8a95a5;
}