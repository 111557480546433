// Colors
//
// Grayscale and brand colors to be used across Bootstrap
$gray-dark:                 #373a3c !default;
$gray:                      #55595c !default;
$gray-light:                #818a91 !default;
$gray-lighter:              #eceeef !default;
$gray-lightest:             #f7f7f9 !default;

$theme-colors: (
        "primary": #00bff0,
        "success" : #4dc824,
        "info" : #00bff0,
        "warning" : #f0ad4e,
        "danger": #fb4a46,
        "secondary": #fff
);

@import "../../../node_modules/bootstrap/scss/functions";

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
// More info: https://getbootstrap.com/docs/4.5/getting-started/theming/#sass-options
$enable-flex:         false;
$enable-rounded:      true;
$enable-shadows:      false;
$enable-gradients:    false;
$enable-transitions:  true;
$enable-hover-media-query: false;
$enable-grid-classes: true;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.

$spacer:                     1rem !default;
$spacer-x:                   $spacer !default;
$spacer-y:                   $spacer !default;

$border-width:               1px !default;

// Body
//
// Settings for the `<body>` element.
$body-bg:                   #f0f0f0 !default;
$body-color:                $gray-dark !default;

// Links
//
// Style anchor elements.
$link-color:                #337ab7 !default;
$link-decoration:           none !default;
$link-hover-color:          #23527c !default;
$link-hover-decoration:     none !default;

// Grid breakpoints
//
// Define the minimum and maximum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    // Extra small screen / phone
        xs: 0,
    // Small screen / phone
        sm: 576px,
    // Medium screen / tablet
        md: 768px,
    // Large screen / desktop
        lg: 992px,
    // Extra large screen / wide desktop
        xl: 1200px
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
//
$container-max-widths: (
        sm: 576px,
        md: 720px,
        lg: 940px,
        xl: 1140px
) !default;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:              12 !default;
$grid-gutter-width:         1.875rem !default; // 30px

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:     "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif:          Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:      Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:           $font-family-sans-serif !default;

// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-size-base:             0.90rem !default;
$font-size-lg:               1.05rem !default;
$font-size-sm:               .775rem !default;
$font-size-xs:               .65rem !default;

$font-size-h1:               2.5rem !default;
$font-size-h2:               2rem !default;
$font-size-h3:               1.75rem !default;
$font-size-h4:               1.5rem !default;
$font-size-h5:               1.25rem !default;
$font-size-h6:               1rem !default;

$display1-size:               6rem !default;
$display2-size:               5.5rem !default;
$display3-size:               4.5rem !default;
$display4-size:               3.5rem !default;

$display1-weight:             300 !default;
$display2-weight:             300 !default;
$display3-weight:             300 !default;
$display4-weight:             300 !default;

$line-height:                1.5 !default;

$headings-margin-bottom:     ($spacer / 2) !default;
$headings-font-family:       inherit !default;
$headings-font-weight:       500 !default;
$headings-line-height:       1.1 !default;
$headings-color:             inherit !default;

$lead-font-size:             1.25rem !default;
$lead-font-weight:           300 !default;

$text-muted:                  $gray-light !default;

$abbr-border-color:           $gray-light !default;

$blockquote-small-color:      $gray-light !default;
$blockquote-font-size:        ($font-size-base * 1.25) !default;
$blockquote-border-color:     $gray-lighter !default;

$hr-border-color:             rgba(0,0,0,.1) !default;
$hr-border-width:             $border-width !default;

$dt-font-weight:              bold !default;

$nested-kbd-font-weight:      bold !default;

$list-inline-padding:         5px !default;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:         (4 / 3) !default;
$line-height-sm:         1.5 !default;

$border-radius:          .25rem !default;
$border-radius-lg:       .3rem !default;
$border-radius-sm:       .2rem !default;

$component-active-color: #fff !default;
$component-active-bg:    theme-color("primary") !default;

$caret-width:            .3em !default;
$caret-width-lg:         $caret-width !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:            .7rem !default;
$table-sm-cell-padding:         .3rem !default;

$table-bg:                      transparent !default;
$table-bg-accent:               #f9f9f9 !default;
$table-bg-hover:                #f5f5f5 !default;
$table-bg-active:               $table-bg-hover !default;

$table-border-width:            $border-width !default;
$table-border-color:            $gray-lighter !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$btn-padding-x:                  1rem !default;
$btn-padding-y:                  .375rem !default;
$btn-font-weight:                normal !default;

$btn-primary-color:              #fff !default;
$btn-primary-bg:                 theme-color("primary") !default;
$btn-primary-border:             $btn-primary-bg !default;

$btn-secondary-color:            $gray-dark !default;
$btn-secondary-bg:               #fff !default;
$btn-secondary-border:           #ccc !default;

$btn-info-color:                 #fff !default;
$btn-info-bg:                    theme-color("info") !default;
$btn-info-border:                $btn-info-bg !default;

$btn-success-color:              #fff !default;
$btn-success-bg:                 theme-color("success") !default;
$btn-success-border:             $btn-success-bg !default;

$btn-warning-color:              #fff !default;
$btn-warning-bg:                 theme-color("warning") !default;
$btn-warning-border:             $btn-warning-bg !default;

$btn-danger-color:               #fff !default;
$btn-danger-bg:                  theme-color("danger") !default;
$btn-danger-border:              $btn-danger-bg !default;

$btn-link-disabled-color:        $gray-light !default;

$btn-padding-x-sm:               .75rem !default;
$btn-padding-y-sm:               .25rem !default;

$btn-padding-x-lg:               1.25rem !default;
$btn-padding-y-lg:               .75rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:              $border-radius !default;
$btn-border-radius-lg:           $border-radius-lg !default;
$btn-border-radius-sm:           $border-radius-sm !default;

// Forms

$input-padding-x:                .75rem !default;
$input-padding-y:                .375rem !default;

$input-bg:                       #fff !default;
$input-bg-disabled:              $gray-lighter !default;

$input-color:                    $gray !default;
$input-border-color:             #ccc !default;
$input-btn-border-width:         $border-width !default; // For form controls and buttons
$input-box-shadow:               inset 0 1px 1px rgba(0,0,0,.075) !default;

$input-border-radius:            $border-radius !default;
$input-border-radius-lg:         $border-radius-lg !default;
$input-border-radius-sm:         $border-radius-sm !default;

$input-border-focus:             #66afe9 !default;
$input-box-shadow-focus:         rgba(102,175,233,.6) !default;

$input-color-placeholder:        #999 !default;

$input-padding-x-sm:             .75rem !default;
$input-padding-y-sm:             .275rem !default;

$input-padding-x-lg:             1.25rem !default;
$input-padding-y-lg:             .75rem !default;

$input-height:                   (($font-size-base * $line-height) + ($input-padding-y * 2)) !default;
$input-height-lg:                (($font-size-lg * $line-height-lg) + ($input-padding-y-lg * 2)) !default;
$input-height-sm:                (($font-size-sm * $line-height-sm) + ($input-padding-y-sm * 2)) !default;

$form-group-margin-bottom:       $spacer-y !default;

$input-group-addon-bg:           $gray-lighter !default;
$input-group-addon-border-color: $input-border-color !default;

$cursor-disabled:                not-allowed !default;

// Form validation icons
$form-icon-success: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==" !default;
$form-icon-warning: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+" !default;
$form-icon-danger: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=" !default;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-bg:                    #fff !default;
$dropdown-border-color:          rgba(0,0,0,.15) !default;
$dropdown-border-width:          $border-width !default;
$dropdown-divider-bg:            #e5e5e5 !default;

$dropdown-link-color:            $gray-dark !default;
$dropdown-link-hover-color:      darken($gray-dark, 5%) !default;
$dropdown-link-hover-bg:         #f5f5f5 !default;

$dropdown-link-active-color:     $gray-dark !default;
$dropdown-link-active-bg:        #eee !default;

$dropdown-link-disabled-color:   $gray-light !default;

$dropdown-header-color:          $gray-light !default;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-navbar:            1000 !default;
$zindex-dropdown:          1000 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;
$zindex-navbar-fixed:      1030 !default;
$zindex-navbar-sticky:     1030 !default;
$zindex-modal-bg:          1040 !default;
$zindex-modal:             1050 !default;

// Navbar

$navbar-border-radius:              $border-radius !default;
$navbar-padding-y:                  0rem !default;
$navbar-padding-x:                  0rem !default;

$navbar-dark-color:                 rgba(255,255,255,.5) !default;
$navbar-dark-hover-color:           rgba(255,255,255,.75) !default;
$navbar-dark-active-color:          rgba(255,255,255,1) !default;
$navbar-dark-disabled-color:        rgba(255,255,255,.25) !default;

$navbar-light-color:                rgba(0,0,0,.3) !default;
$navbar-light-hover-color:          rgba(0,0,0,.6) !default;
$navbar-light-active-color:         rgba(0,0,0,.8) !default;
$navbar-light-disabled-color:       rgba(0,0,0,.15) !default;


// Navs

$nav-link-padding:                          .5em 1em !default;
$nav-link-hover-bg:                         $gray-lighter !default;

$nav-disabled-link-color:                   $gray-light !default;
$nav-disabled-link-hover-color:             $gray-light !default;

$nav-tabs-border-color:                     #ddd !default;

$nav-tabs-link-border-width:                $border-width !default;
$nav-tabs-link-hover-border-color:          $gray-lighter !default;

$nav-tabs-active-link-hover-bg:             $body-bg !default;
$nav-tabs-active-link-hover-color:          $gray !default;
$nav-tabs-active-link-hover-border-color:   #ddd !default;

$nav-tabs-justified-link-border-color:            #ddd !default;
$nav-tabs-justified-active-link-border-color:     $body-bg !default;

$nav-pills-border-radius:                   $border-radius !default;
$nav-pills-active-link-hover-bg:            $component-active-bg !default;
$nav-pills-active-link-hover-color:         $component-active-color !default;

// Pagination

$pagination-padding-x:                .75rem !default;
$pagination-padding-y:                .5rem !default;
$pagination-padding-x-sm:             .75rem !default;
$pagination-padding-y-sm:             .275rem !default;
$pagination-padding-x-lg:             1.5rem !default;
$pagination-padding-y-lg:             .75rem !default;


$pagination-color:                     $link-color !default;
$pagination-bg:                        #fff !default;
$pagination-border-width:              $border-width !default;
$pagination-border-color:              #ddd !default;

$pagination-hover-color:               $link-hover-color !default;
$pagination-hover-bg:                  $gray-lighter !default;
$pagination-hover-border:              #ddd !default;

$pagination-active-color:              #fff !default;
$pagination-active-bg:                 theme-color("primary") !default;
$pagination-active-border:             theme-color("primary") !default;

$pagination-disabled-color:            $gray-light !default;
$pagination-disabled-bg:               #fff !default;
$pagination-disabled-border:           #ddd !default;


// Pager

$pager-bg:                             $pagination-bg !default;
$pager-border-width:                   $border-width !default;
$pager-border-color:                   $pagination-border-color !default;
$pager-border-radius:                  15px !default;

$pager-hover-bg:                       $pagination-hover-bg !default;

$pager-active-bg:                      $pagination-active-bg !default;
$pager-active-color:                   $pagination-active-color !default;

$pager-disabled-color:                 $pagination-disabled-color !default;

// Jumbotron

$jumbotron-padding:              2rem !default;
$jumbotron-bg:                   $gray-lighter !default;


// Form states and alerts
//
// Define colors for form feedback states and, by default, alerts.

$state-success-text:             #3c763d !default;
$state-success-bg:               #dff0d8 !default;
$state-success-border:           darken($state-success-bg, 5%) !default;

$state-info-text:                #31708f !default;
$state-info-bg:                  #d9edf7 !default;
$state-info-border:              darken($state-info-bg, 7%) !default;

$state-warning-text:             #8a6d3b !default;
$state-warning-bg:               #fcf8e3 !default;
$state-warning-border:           darken($state-warning-bg, 5%) !default;

$state-danger-text:              #a94442 !default;
$state-danger-bg:                #f2dede !default;
$state-danger-border:            darken($state-danger-bg, 5%) !default;

// Cards
$card-spacer-x:            1.25rem !default;
$card-spacer-y:            .75rem !default;
$card-border-width:        1px !default;
$card-border-radius:       $border-radius !default;
$card-border-color:        #e5e5e5 !default;
$card-border-radius-inner: $card-border-radius !default;
$card-cap-bg:              #f5f5f5 !default;
$card-bg:                  #fff !default;

$card-link-hover-color:    #fff !default;

// Tooltips

$tooltip-max-width:           200px !default;
$tooltip-color:               #fff !default;
$tooltip-bg:                  #000 !default;
$tooltip-opacity:             .9 !default;

$tooltip-arrow-width:         5px !default;
$tooltip-arrow-color:         $tooltip-bg !default;


// Popovers

$popover-bg:                          #fff !default;
$popover-max-width:                   276px !default;
$popover-border-width:                $border-width !default;
$popover-border-color:                rgba(0,0,0,.2) !default;

$popover-title-bg:                    darken($popover-bg, 3%) !default;

$popover-arrow-width:                 10px !default;
$popover-arrow-color:                 $popover-bg !default;

$popover-arrow-outer-width:           ($popover-arrow-width + 1) !default;
$popover-arrow-outer-color:           fade-in($popover-border-color, 0.05) !default;


// Labels

$label-default-bg:            $gray-light !default;
$label-primary-bg:            theme-color("primary") !default;
$label-success-bg:            theme-color("success") !default;
$label-info-bg:               theme-color("info") !default;
$label-warning-bg:            theme-color("warning") !default;
$label-danger-bg:             theme-color("danger") !default;

$label-color:                 #fff !default;
$label-link-hover-color:      #fff !default;
$label-font-weight:           bold !default;


// Modals

// Padding applied to the modal body
$modal-inner-padding:         1rem !default;

$modal-title-padding:         1rm !default;
$modal-title-line-height:     $line-height !default;

$modal-content-bg:                             #fff !default;
$modal-content-border-color:                   rgba(0,0,0,.2) !default;

$modal-backdrop-bg:           #000 !default;
$modal-backdrop-opacity:      .5 !default;
$modal-header-border-color:   #e5e5e5 !default;
$modal-footer-border-color:   $modal-header-border-color !default;

$modal-lg:                    900px !default;
$modal-md:                    600px !default;
$modal-sm:                    300px !default;


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding:               15px !default;
$alert-border-radius:         $border-radius !default;
$alert-link-font-weight:      bold !default;
$alert-border-width:          $border-width !default;

$alert-success-bg:            $state-success-bg !default;
$alert-success-text:          $state-success-text !default;
$alert-success-border:        $state-success-border !default;

$alert-info-bg:               $state-info-bg !default;
$alert-info-text:             $state-info-text !default;
$alert-info-border:           $state-info-border !default;

$alert-warning-bg:            $state-warning-bg !default;
$alert-warning-text:          $state-warning-text !default;
$alert-warning-border:        $state-warning-border !default;

$alert-danger-bg:             $state-danger-bg !default;
$alert-danger-text:           $state-danger-text !default;
$alert-danger-border:         $state-danger-border !default;


// Progress bars

$progress-bg:                 #f5f5f5 !default;
$progress-bar-color:          #fff !default;
$progress-border-radius:      $border-radius !default;

$progress-bar-bg:             #0074d9 !default;
$progress-bar-success-bg:     theme-color("success") !default;
$progress-bar-warning-bg:     theme-color("warning") !default;
$progress-bar-danger-bg:      theme-color("danger") !default;
$progress-bar-info-bg:        theme-color("info") !default;


// List group

$list-group-bg:                 #fff !default;
$list-group-border-color:       #ddd !default;
$list-group-border-width:       $border-width !default;
$list-group-border-radius:      $border-radius !default;

$list-group-hover-bg:           #f5f5f5 !default;
$list-group-active-color:       $component-active-color !default;
$list-group-active-bg:          $component-active-bg !default;
$list-group-active-border:      $list-group-active-bg !default;
$list-group-active-text-color:  lighten($list-group-active-bg, 40%) !default;

$list-group-disabled-color:      $gray-light !default;
$list-group-disabled-bg:         $gray-lighter !default;
$list-group-disabled-text-color: $list-group-disabled-color !default;

$list-group-link-color:         #555 !default;
$list-group-link-hover-color:   $list-group-link-color !default;
$list-group-link-heading-color: #333 !default;

// Custom colors
$sidebar-border-color: #4c5462;
$sidebar-border-color-active: #21c1ef;
$sidebar-border-color-hover: #54c735;

$navbar-border-color: #b8b7b7;
$navbar-border-color-lighter: #bdbbbc;

$content-border-color: #e6e6e6;

$table-row-color-highlight: #f5f8fa;
$table-row-color-even: #f0f3f5;
$table-row-color-selected: #edf0f2;

$entity-actions-link-color: #4e515e;
$entity-actions-link-hover: #d1d4d6;

$panel-background-color: #fff;

$placeholder-text-color: #999;

$invalid-value-color: #ce4b48;

// Breadcrumb
$breadcrumb-divider: quote("\276F") !default;

$breadcrumb-font-size:       1.4rem;
$breadcrumb-margin-bottom:   0rem;


// Custom grid
$panel-top-margin: 1.4rem;

$sidebar-vertical-padding: 16px;

$navbar-header-height-min: 80px;
$navbar-header-height-max: 800px;

$navbar-breakpoint: 544px;
$navbar-breakpoint-max: $navbar-breakpoint - 1px;
